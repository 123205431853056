var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: `reload_${_vm.key}`, staticClass: "kt-widget4 p-2" },
    _vm._l(_vm.categoryTotals, function (total) {
      return _c("div", { key: total.key, staticClass: "kt-widget4__item" }, [
        _c("div", { staticClass: "kt-widget4__pic kt-widget4__pic--logo" }, [
          _c("span", { staticStyle: { "font-size": "1.4rem" } }, [
            _vm._v(" " + _vm._s(total.category.categoryIcon) + " "),
          ]),
        ]),
        _c("div", { staticClass: "kt-widget4__info" }, [
          _c(
            "a",
            {
              staticClass: "kt-widget4__title",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.applyFilter(total.category)
                },
              },
            },
            [
              _vm._v(
                " " + _vm._s(total.category.anecdotalEventCategoryTitle) + " "
              ),
            ]
          ),
          _c("p", { staticClass: "kt-widget4__text" }, [
            _vm._v(" " + _vm._s(total.totalEvents) + " events "),
          ]),
        ]),
        total.category.pointValue
          ? _c(
              "span",
              {
                class: [
                  "kt-widget4__number",
                  total.category.pointValue < 0
                    ? " kt-font-danger"
                    : " kt-font-success",
                ],
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.formatNumber(
                        total.totalEvents *
                          parseFloat(total.category.pointValue)
                      )
                    ) +
                    " "
                ),
              ]
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }