<template>
<div :key="`reload_${key}`" class="kt-widget4 p-2">
    <div
        v-for="total in categoryTotals"
        :key="total.key"
        class="kt-widget4__item"
    >
        <div class="kt-widget4__pic kt-widget4__pic--logo">
            <span
                class=""
                style="font-size: 1.4rem;"
            >
                {{ total.category.categoryIcon }}
            </span>
        </div>
        <div class="kt-widget4__info">
            <a
                href="#"
                class="kt-widget4__title"
                @click.stop.prevent="applyFilter(total.category)"
            >
                {{ total.category.anecdotalEventCategoryTitle }}
            </a>
            <p class="kt-widget4__text">
                {{ total.totalEvents }} events
                <!-- {{ total.schoolTerm.name }} -->
            </p>
        </div>
        <span
            v-if="total.category.pointValue"
            :class="[
                'kt-widget4__number',
                total.category.pointValue < 0 ? ' kt-font-danger' : ' kt-font-success'
            ]"
        >
            {{ formatNumber( total.totalEvents * parseFloat(total.category.pointValue) ) }}
        </span>
    </div>
</div>
</template>

<script>

import Vue from 'vue';
import AnimatedNumber from 'animated-number-vue';
import { studentMixins, getStudents, StudentFilter } from '../store/mixins/studentMixins';
import utilMixins from '../store/mixins/utilMixins';
import badgeMixins from '../store/mixins/badgeMixins';
import * as network from '../network';
import Types from '../store/Types';

export default Vue.extend({
    name: 'StudentAnecdotalFilters',
    components: {
        AnimatedNumber,
    },
    mixins: [
        studentMixins,
        utilMixins,
        badgeMixins,
    ],
    props: {
        studentId: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            aggregateStudentAnecdotalCategories: [],
        };
    },
    computed: {
        key() {
            return this.$store.state.database.reloadKey;
        },
        student() {
            const { studentId } = this;
            if (studentId) {
                const students = getStudents(this.$store.state.database, new StudentFilter({ studentId }));
                return students[0];
            }
            return this.$_studentMixins_getStudentFromRoute;
        },
        schoolBadges() {
            const { gradeLevel } = this.student;
            // remove badges not valid for this student
            return this.$store.state.database.badges.filter((b) => {
                if (b.deleted) return false;
                // this isnt by grde level, show it
                if (b.badgeLimiterType == null) return true;

                // limited by grade level
                if (b.badgeLimiterType == 'Grade Level') {
                    const hasGLReqs = b.badgeRequirements.filter((br) => br.badgeRequirementLimiter == gradeLevel);
                    return (hasGLReqs.length > 0);
                }
                return false;
            });
        },
        studentBadge() {
            return this.$_badgeMixins_getStudentBadgeFromRoute;
        },
        topBadge() {
            if (!this.studentBadge) return null;
            const [top] = this.studentBadge.earnedBadges;
            return top;
        },
        earnedBadges() {
            if (!this.studentBadge) return [];
            return this.studentBadge.earnedBadges;
        },
        possibleBadges() {
            const { earnedBadges } = this;
            return this.schoolBadges.map((b) => {
                const badge = { ...b };
                badge.earned = earnedBadges.find((eb) => eb.badgeId === b.badgeId);
                return badge;
            });
        },
        totalEvents() {
            return this.categoryTotals.reduce((acc, row) => acc + parseFloat(row.totalEvents), 0);
        },
        totalPoints() {
            return this.categoryTotals.reduce((acc, row) => acc + parseFloat(row.totalPoints), 0);
        },
        totalPositivePoints() {
            return this.categoryTotals.reduce((acc, row) => acc + parseFloat(row.totalPositivePoints), 0);
        },
        totalNegativePoints() {
            return this.categoryTotals.reduce((acc, row) => acc + parseFloat(row.totalNegativePoints), 0);
        },
        totalResolved() {
            return this.categoryTotals.reduce((acc, row) => acc + parseFloat(row.totalResolved), 0);
        },
        categoryTotals() {
            const { anecdotalEventCategories } = this.$store.state.database;
            const { schoolTerms } = this.$store.state.user;
            // if (!this.studentBadge) return [];
            const { aggregateStudentAnecdotalCategories } = this;
            return aggregateStudentAnecdotalCategories.map((row) => {
                const record = { ...row };
                const { anecdotalEventCategoryId } = row;
                record.key = `${row.anecdotalEventCategoryId}_${row.schoolTermId}`;
                record.category = anecdotalEventCategories.find((category) => category.anecdotalEventCategoryId == anecdotalEventCategoryId) || null;
                const schoolTerm = schoolTerms.find((term) => term.schoolTermId == row.schoolTermId) || null;
                record.schoolTerm = schoolTerm;
                return { ...record };
            }).filter((row) => row.category && row.schoolTerm);
        },
    },
    watch: {
        key() {
            this.populate();
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        formatPoints(value) {
            return this.formatNumber(value);
        },
        populate() {
            const { schoolId, schoolTermId } = this.$store.state.user.school;
            const params = {
                url: {
                    studentId: this.student.studentId,
                    schoolId,
                    schoolTermId,
                },
            };
            network.student.getAggregatesForStudent(params, (err, resp) => {
                if (err) return this.showError(err);
                this.aggregateStudentAnecdotalCategories = resp.aggregateStudentAnecdotalCategories;
            });
        },
        showStudentBadgeDetails(badgeId) {
            // const { showError } = this;
            const params = { ...this.$route.params };
            params.badgeId = badgeId;
            this.$router.push({
                name: 'StudentBadgeDetails',
                params,
            });
        },
        applyFilter(category) {
            if (!category || !category.anecdotalEventCategoryId) return;
            if (this.$route.name !== 'StudentAnecdotals') {
                this.$router.push({
                    name: 'StudentAnecdotals',
                    params: {
                        studentEnrollmentId: this.student.studentEnrollmentId,
                    },
                });
            }
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_CATEGORY, category.anecdotalEventCategoryId);
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
    },
});

</script>

<style scoped>

a.kt-widget4__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

ul.badges {
    list-style: none outside none;
    margin: 0;
    padding: 0;
    text-align: center;
}

ul.badges li {
    margin: 6px;
    display: inline-block;
}

span.kt-widget__label {
    min-width: 110px;
}
a.kt-widget__data i {
    display: none;
}
a.kt-widget__data:hover i {
    display: inline-block;
}
div.kt-widget__media {
    width: 90px;
    height: 90px;
}
img.kt-widget__img {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    border-radius: 6px !important;
    object-fit: cover;
    width: 100%;
    height: 90px;
}

img.big-picture {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    border-radius: 6px !important;
    object-fit: cover;
    width: 100%;
    height: auto;
}
</style>
